<template>
   <div class="box">
    <div class="box-header">
      <h4>{{ pageTitle }}</h4>
    </div>
    <model-form
      v-if="model"
      :submit-text="pageTitle"
      :model="model"
      :is-edit="true"
      @on-submit="editModel"
    >
    </model-form>
    <placeholder-form v-else></placeholder-form>
  </div>
</template>

<script>
import ModelForm from './components/Form'
import PlaceholderForm from '@core/components/Placeholder/PlaceholderForm'
import flatry from '@admin/utils/flatry'
import CouponService from '@admin/services/CouponService'

export default {
  name: 'SendCouponEdit',
  data () {
    return {
      pageTitle: '编辑',
      model: null
    }
  },
  components: {
    ModelForm,
    PlaceholderForm
  },

  async created () {
    const { data } = await flatry(CouponService.sendEdit(this.$router.currentRoute.query.id))
    if (data) {
      let param = data.data.model
      param.coupons = data.data.coupons
      param.where = data.data.where
      param.members = data.data.member
      param.shops = data.data.shopTemp
      param.classify = data.data.classify
      param.tempList = data.data.tempList
      param.smsList = data.data.smsList
      this.model = param
      console.log(this.model)
    }
  },
  methods: {
    async editModel (model, success, callback) {
      console.log(model)
      const { data } = await flatry(CouponService.sendEdit(model.id, model))
      if (data) {
        console.log(data)
        this.$message.success(data.msg)
        this.$router.replace({
          path: '/send-coupon/update',
          query: { id: data.data }
        })
        success()
      }

      callback()
    }
  }

}

</script>
<style lang='' scoped>

</style>
